import { UserProfile } from "@src/types";
import { transformProfileTypeId } from "@src/utils";

export const initPendoSession = (user: UserProfile, userHasOwnerAndTenantProfiles?: boolean) => {
  const { emailAddress, firstName, lastName, userId, profileTypeId } = user;

  if ((window as IntentionalAny<"Exists on window on Prod">).pendo) {
    (window as IntentionalAny<"Exists on window on Prod">).pendo.initialize({
      visitor: {
        id: userId,
        email: emailAddress,
        full_name: `${firstName} ${lastName}`,
        profile_type: userHasOwnerAndTenantProfiles ? "OwnerTenant" : transformProfileTypeId(profileTypeId)
      }
    });
  }
};

export const initPendoSessionWithEmailOnly = (emailAddress: string) => {
  if (!process.env.REACT_APP_Pendo_App_Id) return;

  if ((window as IntentionalAny<"Exists on window on Prod">).pendo) {
    (window as IntentionalAny<"Exists on window on Prod">).pendo.initialize({
      apiKey: process.env.REACT_APP_Pendo_App_Id,
      visitor: {
        id: emailAddress,
        email: emailAddress,
        full_name: emailAddress
      }
    });
  }
};

export const setPendoAccountDetails = (account: IntentionalAny<"Not sure yet">) => {
  (window as IntentionalAny<"Exists on window on Prod">).identify({ account });
};
